import { createContext, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import usePriestContext from '../hooks/usePriestContext';
import { WEDDING_API } from '../utils/constants';
import { saveAs } from 'file-saver';

const WeddingContext = createContext({});

export const WeddingProvider = () => {
   const axiosPrivate = useAxiosPrivate();

   //Loaders
   const [loading, setLoading] = useState(false);
   const [formALoading, setFormALoading] = useState(false);
   const [lackOfFormLoading, setLackOfFormLoading] = useState(false);
   const [dispensationLoading, setDispensationLoading] = useState(false);
   const [groomCertLoading, setGroomCertLoading] = useState(false);
   const [brideCertLoading, setBrideCertLoading] = useState(false);
   const [domicileLoading, setDomicileLoading] = useState(false);
   const [witnessLoading, setWitnessLoading] = useState(false);
   const [witnessFormsLoading, setWitnessFormsLoading] = useState(false);
   const [dioceseLoading, setDioceseLoading] = useState(false);
   //States
   const [weddings, setWeddings] = useState([]);
   const [upcomingWeddings, setUpcomingWeddings] = useState([]);
   const [pastWeddings, setPastWeddings] = useState([]);
   const [filteredWeddings, setFilteredWeddings] = useState([]);
   const { selectedPriests } = usePriestContext();

   const getWeddings = async () => {
      try {
         setLoading(true);
         const { data } = await axiosPrivate.get(WEDDING_API);
         setWeddings(data);
      } catch (err) {
         if (err?.code === 'ERR_NETWORK')
            return {
               message: 'No server response! Check your internet connection.',
            };
         return { message: err?.response?.data?.message || false };
      } finally {
         setLoading(false);
      }
   };

   useEffect(() => {
      setUpcomingWeddings(
         filteredWeddings.filter(
            wedding => new Date(wedding.weddingDate) >= Date.now()
         )
      );
   }, [filteredWeddings]);

   useEffect(() => {
      setPastWeddings(
         filteredWeddings.filter(
            wedding => new Date(wedding.weddingDate) < Date.now()
         )
      );
   }, [filteredWeddings]);

   useEffect(() => {
      if (selectedPriests?.length === 0) {
         setFilteredWeddings(weddings);
      } else {
         setFilteredWeddings(
            weddings.filter(w => selectedPriests.includes(w?.priest?._id))
         );
      }
   }, [selectedPriests, weddings]);

   const addWedding = async data => {
      try {
         setLoading(true);
         const res = await axiosPrivate.post(WEDDING_API, data);
         setWeddings(prev => [...prev, res.data]);
         return undefined;
      } catch (err) {
         if (err?.code === 'ERR_NETWORK')
            return {
               message: 'No server response! Check your internet connection.',
            };
         return { message: err?.response?.data?.message || false };
      } finally {
         setLoading(false);
      }
   };

   const deleteWedding = async id => {
      if (!id) return;
      try {
         setLoading(true);
         await axiosPrivate.delete(`${WEDDING_API}/${id}`);
         setWeddings(prev => prev.filter(w => w._id !== id));
         return undefined;
      } catch (err) {
         if (err?.code === 'ERR_NETWORK')
            return {
               message: 'No server response! Check your internet connection.',
            };
         return { message: err?.response?.data?.message || false };
      } finally {
         setLoading(false);
      }
   };

   const updateWedding = async (id, data) => {
      if (!id || !data || Object.keys(data).length === 0) return;
      try {
         setLoading(true);
         const res = await axiosPrivate.patch(`${WEDDING_API}/${id}`, data);
         setWeddings(w => w.map(w => (w._id === id ? res.data : w)));
         return undefined;
      } catch (err) {
         if (err?.code === 'ERR_NETWORK')
            return {
               message: 'No server response! Check your internet connection.',
            };
         return { message: err?.response?.data?.message || false };
      } finally {
         setLoading(false);
      }
   };

   const uploadFormA = async (weddingId, file) => {
      try {
         setFormALoading(true);
         const res = await axiosPrivate.post(
            `${WEDDING_API}/uploadFormA/${weddingId}`,
            { file },
            { headers: { 'Content-Type': 'multipart/form-data' } }
         );
         setWeddings(w => w.map(w => (w._id === weddingId ? res.data : w)));
         return undefined;
      } catch (err) {
         if (err?.code === 'ERR_NETWORK')
            return {
               message: 'No server response! Check your internet connection.',
            };
         return { message: err?.response?.data?.message || false };
      } finally {
         setFormALoading(false);
      }
   };
   const uploadLackOfForm = async (weddingId, file) => {
      try {
         setLackOfFormLoading(true);
         const res = await axiosPrivate.post(
            `${WEDDING_API}/uploadLackOfForm/${weddingId}`,
            { file },
            { headers: { 'Content-Type': 'multipart/form-data' } }
         );
         setWeddings(w => w.map(w => (w._id === weddingId ? res.data : w)));
         return undefined;
      } catch (err) {
         if (err?.code === 'ERR_NETWORK')
            return {
               message: 'No server response! Check your internet connection.',
            };
         return { message: err?.response?.data?.message || false };
      } finally {
         setLackOfFormLoading(false);
      }
   };
   const uploadDispensation = async (weddingId, file) => {
      try {
         setDispensationLoading(true);
         const res = await axiosPrivate.post(
            `${WEDDING_API}/uploadDispensation/${weddingId}`,
            { file },
            { headers: { 'Content-Type': 'multipart/form-data' } }
         );
         setWeddings(w => w.map(w => (w._id === weddingId ? res.data : w)));
         return undefined;
      } catch (err) {
         if (err?.code === 'ERR_NETWORK')
            return {
               message: 'No server response! Check your internet connection.',
            };
         return { message: err?.response?.data?.message || false };
      } finally {
         setDispensationLoading(false);
      }
   };

   const uploadGroomBaptismCertificate = async (weddingId, file) => {
      try {
         setGroomCertLoading(true);
         const res = await axiosPrivate.post(
            `${WEDDING_API}/uploadGroomBaptismCert/${weddingId}`,
            { file },
            { headers: { 'Content-Type': 'multipart/form-data' } }
         );
         setWeddings(w => w.map(w => (w._id === weddingId ? res.data : w)));
         return undefined;
      } catch (err) {
         if (err?.code === 'ERR_NETWORK')
            return {
               message: 'No server response! Check your internet connection.',
            };
         return { message: err?.response?.data?.message || false };
      } finally {
         setGroomCertLoading(false);
      }
   };

   const uploadBrideBaptismCertificate = async (weddingId, file) => {
      try {
         setBrideCertLoading(true);
         const res = await axiosPrivate.post(
            `${WEDDING_API}/uploadBrideBaptismCert/${weddingId}`,
            { file },
            { headers: { 'Content-Type': 'multipart/form-data' } }
         );
         setWeddings(w => w.map(w => (w._id === weddingId ? res.data : w)));
         return undefined;
      } catch (err) {
         if (err?.code === 'ERR_NETWORK')
            return {
               message: 'No server response! Check your internet connection.',
            };
         return { message: err?.response?.data?.message || false };
      } finally {
         setBrideCertLoading(false);
      }
   };

   const uploadDomicilePastorPermission = async (
      weddingId,
      permission,
      person,
      file
   ) => {
      const formData = new FormData();
      if (person === 'bride') {
         formData.append('bride_domicilePastorPermission', permission);
      } else if (person === 'groom') {
         formData.append('groom_domicilePastorPermission', permission);
      }

      if (file) formData.append('file', file);

      try {
         setDomicileLoading(true);
         const res = await axiosPrivate.post(
            `${WEDDING_API}/uploadDomicilePermission/${weddingId}`,
            formData,
            { headers: { 'Content-Type': 'multipart/form-data' } }
         );
         setWeddings(w => w.map(w => (w._id === weddingId ? res.data : w)));
         return undefined;
      } catch (err) {
         if (err?.code === 'ERR_NETWORK')
            return {
               message: 'No server response! Check your internet connection.',
            };
         return { message: err?.response?.data?.message || false };
      } finally {
         setDomicileLoading(false);
      }
   };

   const addWitnesses = async (id, data) => {
      setWitnessLoading(true);
      try {
         const res = await axiosPrivate.post(
            `${WEDDING_API}/addWitnesses/${id}`,
            data
         );
         setWeddings(w => w.map(w => (w._id === id ? res.data : w)));
         return undefined;
      } catch (err) {
         if (err?.code === 'ERR_NETWORK')
            return {
               message: 'No server response! Check your internet connection.',
            };
         return { message: err?.response?.data?.message || false };
      } finally {
         setWitnessLoading(false);
      }
   };

   const uploadWitnessForms = async (weddingId, file) => {
      try {
         setWitnessFormsLoading(true);
         const res = await axiosPrivate.post(
            `${WEDDING_API}/uploadWitnessForms/${weddingId}`,
            { file },
            { headers: { 'Content-Type': 'multipart/form-data' } }
         );
         setWeddings(w => w.map(w => (w._id === weddingId ? res.data : w)));
         return undefined;
      } catch (err) {
         if (err?.code === 'ERR_NETWORK')
            return {
               message: 'No server response! Check your internet connection.',
            };
         return { message: err?.response?.data?.message || false };
      } finally {
         setWitnessFormsLoading(false);
      }
   };

   const updateDioceseClass = async (id, data) => {
      setDioceseLoading(true);
      try {
         const res = await axiosPrivate.post(
            `${WEDDING_API}/updateDiocese/${id}`,
            data
         );
         setWeddings(w => w.map(w => (w._id === id ? res.data : w)));
         return undefined;
      } catch (err) {
         if (err?.code === 'ERR_NETWORK')
            return {
               message: 'No server response! Check your internet connection.',
            };
         return { message: err?.response?.data?.message || false };
      } finally {
         setDioceseLoading(false);
      }
   };

   const downloadXLSX = async () => {
      try {
         const res = await axiosPrivate.get(`${WEDDING_API}/xlsx`, {
            responseType: 'blob',
         });
         saveAs(res.data, 'Weddings.xlsx');
      } catch (err) {
         console.log(err);
         if (err?.code === 'ERR_NETWORK')
            return {
               message: 'No server response! Check your internet connection.',
            };
         return { message: err?.response?.data?.message || false };
      }
   };

   useEffect(() => {
      let mounted = true;
      if (mounted) {
         getWeddings();
      }

      return () => (mounted = false);
      //eslint-disable-next-line
   }, []);

   /* 
   -----------------------------
   Issue Contexts
   -----------------------------
   */
   const nonCatholicWeddings = filteredWeddings
      .map(wedding => {
         if (
            wedding.groom_baptismStatus.toLowerCase() === 'baptized orthodox' ||
            wedding.groom_baptismStatus.toLowerCase() ===
               'baptized protestant' ||
            wedding.bride_baptismStatus.toLowerCase() === 'baptized orthodox' ||
            wedding.bride_baptismStatus.toLowerCase() === 'baptized protestant'
         )
            return wedding;
         return '';
      })
      .filter(Boolean);

   const domicileIssueWeddings = filteredWeddings
      .map(wedding => {
         if (
            wedding.groom_withinParish === false ||
            wedding.bride_withinParish === false
         )
            return wedding;
         return '';
      })
      .filter(Boolean);

   const notBaptizedWeddings = filteredWeddings.filter(
      wedding =>
         wedding.groom_baptismStatus.toLowerCase() === 'not baptized' ||
         wedding.bride_baptismStatus.toLowerCase() === 'not baptized'
   );

   const hasChurchPriorMarriage = filteredWeddings
      .map(wedding => {
         if (
            wedding.groom_priorMarriage.toLowerCase() === 'church wedding' ||
            wedding.groom_priorMarriage2.toLowerCase() === 'church wedding' ||
            wedding.bride_priorMarriage.toLowerCase() === 'church wedding' ||
            wedding.bride_priorMarriage2.toLowerCase() === 'church wedding'
         )
            return wedding;
         return '';
      })
      .filter(Boolean);

   return (
      <WeddingContext.Provider
         value={{
            weddings,
            filteredWeddings,
            upcomingWeddings,
            pastWeddings,
            setWeddings,
            loading,
            addWedding,
            deleteWedding,
            updateWedding,
            uploadFormA,
            formALoading,
            lackOfFormLoading,
            uploadLackOfForm,
            dispensationLoading,
            uploadDispensation,
            uploadGroomBaptismCertificate,
            groomCertLoading,
            uploadBrideBaptismCertificate,
            brideCertLoading,
            nonCatholicWeddings,
            domicileIssueWeddings,
            domicileLoading,
            uploadDomicilePastorPermission,
            notBaptizedWeddings,
            hasChurchPriorMarriage,
            witnessLoading,
            addWitnesses,
            uploadWitnessForms,
            witnessFormsLoading,
            dioceseLoading,
            updateDioceseClass,
            downloadXLSX,
         }}
      >
         <Outlet />
      </WeddingContext.Provider>
   );
};

export default WeddingContext;
